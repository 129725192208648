import React from 'react';
import { motion } from "framer-motion";
import Conctatenos from './Conctatenos';

const SobreNosotros = () => {
  return (
    <div>
      <motion.div className='m-auto mt-3' 
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{duration: 1}}
      style={{maxWidth: '1000px'}}>
        <h1 style={{fontFamily: 'Yeseva One', fontSize: '50px'}}>Historia</h1>
        <p style={{fontFamily: 'Fira Sans Extra Condensed', textAlign: 'justify'}}>En el corazón de <b>PROWEBSPORTS</b> yace una historia de pasión por la natación, la tecnología y la sostenibilidad. Nuestra plataforma nació de la visión de transformar la manera en que los entrenadores de natación organizan y gestionan sus torneos, al tiempo que contribuimos a la protección del medio ambiente.
        Todo comenzó con un equipo de amantes de la natación que también eran conscientes de los desafíos que enfrentan los entrenadores al organizar competencias. La logística, la administración y la documentación tradicional requerían tiempo y recursos significativos, mientras que la preocupación por el uso excesivo de papel crecía en una era de conciencia ambiental creciente.
        Inspirados por la idea de facilitar la vida de los entrenadores y reducir el impacto en el planeta, nos embarcamos en un viaje de desarrollo tecnológico. Así nació <b>PROWEBSPORTS</b>, una plataforma que reúne la pasión por la natación con la innovación digital y el compromiso con la sostenibilidad.
        Desde entonces, hemos trabajado incansablemente para ofrecer a los entrenadores de natación una herramienta intuitiva y eficiente que simplifica la organización de torneos. Nuestra plataforma permite la gestión de inscripciones, programación digital y en .pdf, resultados en tiempo real y entre otras funcionalidades, todo en un solo lugar, liberando tiempo valioso para que los entrenadores puedan centrarse en lo que más importa: el éxito y el desarrollo de sus nadadores.
        Pero <b>PROWEBSPORTS</b> es más que solo eficiencia y practicidad. Somos defensores apasionados del medio ambiente, y nos enorgullece contribuir a la reducción del consumo de papel en la industria de la natación. Al digitalizar los procesos que antes requerían montañas de documentos impresos, estamos haciendo nuestra parte para proteger los recursos naturales y promover prácticas sostenibles.
        Nuestro viaje está marcado por la colaboración con entrenadores, nadadores y la comunidad de la natación en general. Cada día, nos inspira ver cómo <b>PROWEBSPORTS</b> impacta positivamente en la vida de las personas y en el mundo que compartimos.
        Así que, bienvenidos a <b>PROWEBSPORTS</b>, donde la pasión por la natación, la innovación tecnológica y la responsabilidad ambiental se fusionan para construir un futuro más brillante tanto en la piscina como fuera de ella.
        <b> ¡Únete a nosotros en esta emocionante travesía!</b></p>
      </motion.div>
      <div className='m-auto m-3' style={{maxWidth: '1300px'}}>
        <motion.div className='mt-5 d-flex justify-content-start align-items-center'
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{duration: 1}}
        style={{textAlign: 'justify'}}
        >
          <div className='w-50' style={{maxWidth: '300px'}}>
            <img className='w-100' src={require('../img/vision.png')} alt="logo vision" />
          </div>
          <div className='w-100 ms-1' style={{maxWidth: '500px'}}>
            <h3 style={{fontFamily: 'Abril Fatface'}}>Visión:</h3>
            <p style={{fontFamily: 'Fira Sans Extra Condensed'}}>
            Nuestra visión en <b>PROWEBSPORTS</b> es convertirnos en la plataforma líder a nivel mundial que redefine la gestión de torneos de natación a través de la tecnología innovadora y la sostenibilidad. 
            Visualizamos un futuro donde nuestra plataforma sea la elección preferida de entrenadores, nadadores y aficionados para lograr experiencias de torneos excepcionales y sin papeleo. 
            Queremos ser un catalizador para la adopción de prácticas responsables en la industria, liderando el camino hacia eventos deportivos más eficientes y ecológicos. 
            A medida que avanzamos, esperamos construir una comunidad global unida en torno a la natación, la excelencia tecnológica y el compromiso con el medio ambiente.
            </p>
          </div>
        </motion.div>
        <motion.div className='mt-5 d-flex justify-content-end align-items-center'
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{duration: 1}}
        style={{textAlign: 'justify'}}
        >
          <div className='w-100 me-1' style={{maxWidth: '500px'}}>
            <h3 style={{fontFamily: 'Abril Fatface'}}>Misión:</h3>
            <p style={{fontFamily: 'Fira Sans Extra Condensed'}}>
            Nuestra misión en <b>PROWEBSPORTS</b> es simplificar y mejorar el mundo de la natación a través de soluciones tecnológicas avanzadas y sostenibles. 
            Nos dedicamos a proporcionar a los entrenadores herramientas innovadoras que agilicen la organización de torneos y fomenten el desarrollo deportivo. 
            Al hacerlo, aspiramos a ser líderes en la promoción de prácticas responsables en la industria, reduciendo el uso de papel y contribuyendo a la protección del medio ambiente. 
            En <b>PROWEBSPORTS</b>, combinamos la pasión por la natación con la excelencia tecnológica para enriquecer la experiencia deportiva y crear un impacto positivo en la comunidad y el entorno que nos rodea.
            </p>
          </div>
          <div className='w-50' style={{maxWidth: '300px'}}>
            <img className='w-100' src={require('../img/mision.png')} alt="logo mision" />
          </div>
        </motion.div>
      </div>
      <Conctatenos/>
    </div>
  );
}

export default SobreNosotros;