import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { IoLogoFacebook, IoLogoWhatsapp, IoMdContacts, IoMdRibbon, IoMdMail, IoLogoInstagram, IoMdPerson, IoLogoYoutube } from 'react-icons/io'

const Conctatenos = () => {

  const anioAutor = new Date();

  return (
    <motion.div id='contactenos' className='text-start mt-5' style={{ background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.3), rgba(0, 0, 0, 0.2), rgba(47, 157, 247, 0.3))'}}
    initial={{opacity: 0}}
    whileInView={{opacity: 1}}
    transition={{duration: 1, delay: 0.3}}
    >
      <h4 className='p-3 mb-0' style={{fontFamily: 'Yeseva One'}}>Para mayor información comunicate con nosotros.</h4>
      <div className='d-flex flex-wrap justify-content-around'>
        <div className='m-3' style={{maxWidth: '300px'}}>
          <img className='w-100' src={require('../img/img_logo_swimmingT.png')} alt="img logo swimming" />
        </div>
        <div className='m-3'>
          <h5><IoMdRibbon className='fs-2 text-warning'/>PROWEBSPORTS</h5>
          <div className='d-flex flex-column'>
            <Link to="mailto:pws@prowebsports.com.co" target='_blank' style={{textDecoration: 'none'}} className='text-dark'><IoMdMail className='fs-2 text-secondary'/> pws@prowebsports.com.co</Link>
            <Link to="https://wa.me/573245884041" target='_blank' style={{textDecoration: 'none'}} className='text-dark'><IoLogoWhatsapp className='fs-2 text-success'/> +57 324-588-4041</Link>
            <Link to="https://www.facebook.com/emanuel.barbosa.908132" target='_blank' style={{textDecoration: 'none'}} className='text-dark'><IoLogoFacebook className='fs-2 text-primary'/> Prowebsports Sas</Link>
            <Link to="https://www.instagram.com/prowebsports/" target='_blank' style={{textDecoration: 'none'}} className='text-dark'><IoLogoInstagram className='fs-2' style={{color: 'purple'}}/> prowebsports</Link>
            <Link to="https://www.youtube.com/@emanuelbarbosa2735" target='_blank' style={{textDecoration: 'none'}} className='text-dark'><IoLogoYoutube className='fs-2 text-danger'/> prowebsports</Link>
          </div>
          <p className='mt-4'>© 2023 - {anioAutor.getFullYear()} prowebsports</p>
        </div>
        <div className='m-3'>
          <h6><IoMdContacts className='fs-2 text-info'/>Equipo</h6>
          <div className='d-flex flex-column'>
            <p className='m-0'><IoMdPerson className='fs-5 text-secondary'/> Admon. Willman Barbosa Velasquez.</p>
            <Link to="https://wa.me/573137394026" target='_blank' style={{textDecoration: 'none'}} className='text-dark'>+57 313-739-4026</Link>
            <p className='m-0'><IoMdPerson className='fs-5 text-secondary'/>Ing. Emanuel Barbosa Labrada.</p>
            <Link to="https://wa.me/573245884041" target='_blank' style={{textDecoration: 'none'}} className='text-dark'>+57 324-588-4041</Link>
            <p className='m-0'><IoMdPerson className='fs-5 text-secondary'/>Ing. Ywrani Barbosa Labrada.</p>
            <Link to="https://wa.me/573209147444" target='_blank' style={{textDecoration: 'none'}} className='text-dark'>+57 320-914-7444</Link>
            <p className='m-0'><IoMdPerson className='fs-5 text-secondary'/>Ing. Laura Caterine Pinzón Ruco.</p>
            <Link to="https://wa.me/573207788108" target='_blank' style={{textDecoration: 'none'}} className='text-dark'>+57 320-778-8108</Link>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default Conctatenos;