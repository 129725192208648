// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { 
  getAuth, 
  onAuthStateChanged, 
  signOut,
  signInWithEmailAndPassword
} from "firebase/auth";
import { getFirestore,  collection, onSnapshot, doc, getDoc, setDoc, updateDoc, getDocs, deleteDoc } from "firebase/firestore";
import { toast } from "react-hot-toast";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
//Base de datos de prowebsports inscripciones, tiempos, planillas, etc...
const firebaseConfig = {
  apiKey: "AIzaSyB2JfPd9BCJXSBkT5ihk04dvAdrRUFygbc",
  authDomain: "prowebsports.firebaseapp.com",
  projectId: "prowebsports",
  storageBucket: "prowebsports.appspot.com",
  messagingSenderId: "526046283180",
  appId: "1:526046283180:web:1c24920d5faf4b0d0ce609",
  measurementId: "G-XDNEZY0ZEN"
};

const firebaseConfigFamilia = {
  apiKey: "AIzaSyD1PKupANYM3CX1JO-CMsBX63QMaNLF11Q",
  authDomain: "pws-familia.firebaseapp.com",
  projectId: "pws-familia",
  storageBucket: "pws-familia.appspot.com",
  messagingSenderId: "121430827570",
  appId: "1:121430827570:web:857221a585028b02e1522b",
  measurementId: "G-XN337H7J99"
};

// Initialize Firebase 
const db = initializeApp(firebaseConfig);
const auth = getAuth(db);
const firestore = getFirestore(db);

//Initialize Firebase resultados para familiares y demas Prowebsports
const dbFamilia = initializeApp(firebaseConfigFamilia, 'db2');
const firestoreFamilia = getFirestore(dbFamilia);

//Con esta funcion vamos a saber los cambios en la sesión.
export function sesionIniciada(setUsuario){
  onAuthStateChanged(auth, (usuarioFirebase) => {
    let datosUsuario = {};
    if(usuarioFirebase){
      datosUsuario = {
        nombreclub: auth.currentUser.displayName,
        id: usuarioFirebase ? usuarioFirebase.uid : "",
        photo: auth.currentUser.photoURL,
        email: auth.currentUser.email,
        presidente: '',
        tel: ''
      }

      setUsuario(datosUsuario);
    }else{
      setUsuario();
    }
  });
}

//Funcion para ingresar a la plataforma.
export async function login(email, password){
  signInWithEmailAndPassword(auth, email, password)
    .then(() => {
      window.location.reload();
    })
    .catch((err) => {
      if(err.code === 'auth/wrong-password'){
        toast.error("Contraseña incorrecta. Por favor, intentalo de nuevo.");
      }
      if(err.code === 'auth/user-not-found'){
        toast.error("¡UPS! el correo " + email + ", no esta registrado aún.");
      }
      if(err.code === 'auth/user-disabled'){
        toast.error("Lo sentimos, pero esta cuenta ha sido inhabilitada, comunicate con el equipo de PROWEBSPORTS.");
      }
      if(err.code === 'auth/network-request-failed'){
        toast.error('Sin conexion a internet');
      }
    });
}

//Funcion para cerrar sesión.
export function cerrarSesion(){
  signOut(auth);
}

// configuracion de traer datos de los torneos
//Esta funcion nos ayudara a traer los datos del club.
export async function traerClientes(setCliente) {
  const docRef = collection(firestore, `clientes`);
  onSnapshot(docRef, (snapshot) => {
    const auxClientes = [];
    const fechaActualISO = new Date().toISOString().split('T')[0];

    snapshot.forEach((cliente) => {
      if (cliente.data().tipo === 'torneos' && cliente.data().torneos.length !== 0) {
        cliente.data().torneos.forEach((torn)=>{
          if(torn.publicidad === 'si'){
            torn.club = cliente.data().club;
            auxClientes.push(torn);
          }
        })
      }
    });
    auxClientes.forEach((a, b) => {
      const fechaA = new Date(a.fechaF);
      const fechaB = new Date(b.fechaF);
      const diferenciaA = Math.abs(fechaA - new Date(fechaActualISO)) * (fechaA < new Date(fechaActualISO) ? 1 : -1);
      const diferenciaB = Math.abs(fechaB - new Date(fechaActualISO)) * (fechaB < new Date(fechaActualISO) ? 1 : -1);
      return diferenciaA - diferenciaB;
    });
    // Recorrer el array de clientes y agregar el atributo "vencimiento"
    auxClientes.forEach(tor => {
      const fechaActual = new Date(tor.fechaF);
      // Ajustar fechaActual para que sea a las 00:00:00
      fechaActual.setDate(fechaActual.getDate() + 1);
      fechaActual.setHours(0, 0, 0, 0);

      const fechaTorneo = new Date();
      // Ajustar fechaTorneo para que sea a las 00:00:00
      fechaTorneo.setHours(0, 0, 0, 0);

      if (fechaTorneo <= fechaActual) {
        tor.vencimiento = false; // Agregar el atributo vencimiento con valor true
      } else {
        tor.vencimiento = true; // Agregar el atributo vencimiento con valor false
      }

      if (fechaTorneo < fechaActual) {
        tor.estado = 'inscripciones';
      } else if(fechaTorneo > fechaActual) {
        tor.estado = 'terminado';
      } else{
        tor.estado = 'evento';
      }
    });

    auxClientes.sort((a, b) => {
      const fechaA = new Date(a?.fechaF || fechaActualISO);
      const fechaB = new Date(b?.fechaF || fechaActualISO);

      // Verifica si la fecha ya ha pasado y ajusta el orden en consecuencia
      if (fechaA < new Date(fechaActualISO)) {
        return 1; // Mueve al final de la lista
      } else if (fechaB < new Date(fechaActualISO)) {
        return -1; // Mantiene en su posición actual
      } else {
        return Math.abs(fechaA - new Date(fechaActualISO)) - Math.abs(fechaB - new Date(fechaActualISO));
      }
    });

    setCliente(auxClientes);
  });
}
// fin configuracion de traer datos de los torneos

//Esta funcion nos ayudara a modificar los datos del club.
export async function registrarCliente(cliente){
  const docRef = doc(firestore, `clientes/${cliente.club}`);
  const docSnap = await getDoc(docRef);
  if(!docSnap.exists()){
    setDoc(docRef, cliente
    ).then(() =>{
      toast.success('Cliente registrado correctamente.');
    });
  }else{
    updateDoc(docRef, {
      vence: cliente.vence,
      Fpago: cliente.Fpago,
      pagar: cliente.pagar
    }).then(() =>{
      toast.success('Cliente Actualizado correctamente.');
    });
  }
}

//Esta funcion nos ayudara a traer los datos del club.
export async function traerClientesAdmin(setCliente){
  const docRef = collection(firestore, `clientes`);
  onSnapshot(docRef, (snapshot)=>{
    const auxClientes = [];
    snapshot.forEach((cliente)=>{
      auxClientes.push(cliente.data())
    })

    auxClientes.forEach((afi)=>{
      if(afi.vence && afi.vence !== ''){
        const fechaActual = new Date().getTime();
        const fechaPago = new Date(afi.vence).getTime();
        const distancia = fechaPago - fechaActual;
  
        // Convertir la diferencia de milisegundos a días
        const distanciaEnDias = distancia / (1000 * 60 * 60 * 24);
  
        // Calcular el número aproximado de meses
        const mesesFaltantes = Math.floor(distanciaEnDias / 30);
        if(mesesFaltantes <= 0){
          afi.totalAPagar = Math.abs(mesesFaltantes * 25000);
        }else{
          afi.totalAPagar = 0;
        }
      }
    })


    setCliente(auxClientes);
  })
}

//Esta funcion nos ayudara a modificar los datos de la evaluación.
export async function registrarEvaluacion(total){
  const date = new Date();
  const anio = date.getFullYear();
  const docRef = doc(firestore, `anio/${anio + ''}`);
  const docSnap = await getDoc(docRef);
  if(!docSnap.exists()){
    setDoc(docRef, {total: total + ''}
    ).then(() =>{
      toast.success('Evaluación registrada correctamente.');
    });
  }else{
    updateDoc(docRef, {total: total + ''}
    ).then(() =>{
      toast.success('Evaluación actualizada correctamente.');
    });
  }
}

//Esta funcion nos ayudara a modificar los datos de la numeracion factura.
export async function registrarNumeracionFactura(numeracion){
  const date = new Date();
  const anio = date.getFullYear();
  const docRef = doc(firestore, `anio/${anio + ''}`);
  const docSnap = await getDoc(docRef);
  if(!docSnap.exists()){
    setDoc(docRef, {factura: numeracion}
    ).then(() =>{
      toast.success('Numeración registrada correctamente.');
    });
  }else{
    updateDoc(docRef, {factura: numeracion}
    ).then(() =>{
      toast.success('Numeración actualizada correctamente.');
    });
  }
}

//Esta funcion nos ayudara a traer los datos de la evaluación.
export async function traerEvaluacion(setTotalEvaluaciones){
  const docRef = collection(firestore, `anio`);
  onSnapshot(docRef, (snapshot)=>{
    const auxAnios = [];
    snapshot.forEach((anios)=>{
      const aux = {
        anio: parseInt(anios.id),
        factura: anios.data().factura ? anios.data().factura : 0,
        total: parseInt(anios.data().total ? anios.data().total : 0)
      }
      auxAnios.push(aux);
    })

    setTotalEvaluaciones(auxAnios);
  })
}

export function torneoPagado(club, torneos){
  const docRef = doc(firestore, `clientes/${club}`);
  updateDoc(docRef, {torneos});
}

export function activarPlataforma(club){
  const docRef = doc(firestore, `clientes/${club}`);
  updateDoc(docRef, {pf: true});
}

export function desactivarPlataforma(club){
  const docRef = doc(firestore, `clientes/${club}`);
  updateDoc(docRef, {pf: false});
}

//---------------------- configuracion reacciones ----------------------
export async function registrarReaccion(reacciones){
  const docRef = doc(firestoreFamilia, `reaccion/reacciones`);
  const docSnap = await getDoc(docRef)
  if(docSnap.exists()){   
    updateDoc(docRef, reacciones);
  }else{
    setDoc(docRef, reacciones);
  }
}

export async function traerReacciones(setReacciones){
  const tiemposCollectionRef = collection(firestoreFamilia, `reaccion`);
  
  onSnapshot(tiemposCollectionRef, (querySnapshot) => {
    const resultados = querySnapshot.docs
      .filter((doc) => doc.exists)
      .map((doc) => doc.data())

      setReacciones(...resultados);
  });
}
//---------------------- fin configuracion reacciones ----------------------
//---------------------- configuracion afiliados ----------------------
//esta funcion nos ayudara a registrar todos los nadadores y asi tener un registro general de ellos.
export async function registrarDeportistaGeneral(id, deport){
  const docRef = doc(firestore, `ranking/${id}`);
  const docSnap = await getDoc(docRef)
  if(!docSnap.exists()){
    setDoc(docRef, deport);
    toast.success('Deportista registrado correctamente.');
  }else{
    updateDoc(docRef, {edad: deport.edad, federacion: deport.federacion});
    toast.success('Deportista Federado correctamente.');
  }
}

//esta funcion nos ayudara a eliminar el nadador federado.
export async function eliminarDeportistaGeneral(id){
  const docRef = doc(firestore, `ranking/${id}`);
  const docSnap = await getDoc(docRef)
  if(docSnap.exists()){
    deleteDoc(docRef);
    toast.success('Deportista Eliminado correctamente.');
  }
}

//esta funcion nos ayudara a reiniciar el ranking.
export async function reiniciarRankingGeneral(){
  const docRef = collection(firestore, `ranking`);
  const docSnap = await getDocs(docRef);
  const auxRanking = [];
  docSnap.forEach((ran)=>{
    auxRanking.push(ran.data());
  })
  if(auxRanking.length !== 0){
    auxRanking.forEach((auxRan)=>{
      const docRef2 = doc(firestore, `ranking/${auxRan.identificacion}`);
      updateDoc(docRef2, {pruebas: [], federacion: false, puntos: '0'});
    })
    toast.success('El Ranking se ha reiniciado correctamente.');
  }
}

//esta funcion nos ayudara a traer los ranking.
export async function traerRanking(setRanking, setRankingIndividual){
  const docRef = collection(firestore, `ranking`);
  onSnapshot(docRef, (querySnapshot) => {
    const auxRanking = querySnapshot.docs
        .filter((doc) => doc.exists)
        .map((doc) => doc.data());

    setRankingIndividual(auxRanking);
    const auxNadadores = {};
    auxRanking.forEach((nad)=>{
      const edad = parseInt(nad.edad);
      if(edad >= 10 && edad <= 18){
        if (!auxNadadores[edad]) {
            auxNadadores[edad] = [];
        }
        auxNadadores[edad].push(nad);
      }
      if(edad >= 19){
        if (!auxNadadores['19 y Mas']) {
          auxNadadores['19 y Mas'] = [];
        }
        auxNadadores['19 y Mas'].push(nad);
      }
    })
    setRanking(auxNadadores);
  })
}

export async function traerDeportistaParaFederar(id, setDeportistaEncontrado, calcularEdad){
  const docRef = doc(firestore, `ranking/${id}`);
  const docSnap = await getDoc(docRef)
  if(docSnap.exists()){
    docSnap.data().edad = calcularEdad(docSnap.data().fechaNacimiento);
    if(!docSnap.data().federacion){
      toast.success('Este deportista se encuentra NO Federado actualmente.');
    }
    setDeportistaEncontrado(docSnap.data());
  }else{
    toast.error('El deportista que intenta buscar, no se encuentra en la lista de federados.');
  }
}
//---------------------- fin configuracion afiliados ----------------------