import jsPDF from 'jspdf';

export function arreglarTiempoEstandar(time){
  const auxTiemposSeparados = time.split(':');
  const minutos = parseInt(auxTiemposSeparados[0]);
  const segundos = parseInt(auxTiemposSeparados[1]);
  const milisegundos = parseInt(auxTiemposSeparados[2]);
  if(minutos !== 0){
    return minutos + ':' + segundos + '.' + milisegundos;
  }else{
    return segundos + '.' + milisegundos;
  }
}

function arreglarNombres(nombre){
  let nombreArreglado = '';
  switch (nombre) {
    case 'profesionales-florida-guerreros':
      nombreArreglado = 'PROFESIONALES FLORIDA GUERREROS'
      break;
    case 'fundacion-arbe':
      nombreArreglado = 'FUNDACIÓN DEPORTE Y RECREACIÓN ARBE'
      break;
    case 'fecna':
      nombreArreglado = 'FEDERACIÓN COLOMBIANA DE NATACIÓN'
      break;
    case 'linavalle':
      nombreArreglado = 'LIGA VALLECAUCANA DE NATACIÓN'
      break;
    case 'atlas-narino':
      nombreArreglado = 'CLUB DE NATACIÓN ATLAS'
      break;
    case 'orcas-valle':
      nombreArreglado = 'CLUB DEPORTIVO ESCUELA DE NATACIÓN ORCAS VALLE'
      break;
    case 'atlas-narino-admin':
      nombreArreglado = 'CLUB DE NATACIÓN ATLAS'
      break;
    case 'atlas-narino-futbol':
      nombreArreglado = 'CLUB DEPORTIVO ESCUELA DE FUBTOL ATLAS'
      break;
    case 'polares':
      nombreArreglado = 'CLUB DEPORTIVO ESCUELA DE NATACIÓN POLARES'
      break;
    case 'piranitas':
      nombreArreglado = 'CLUB DEPORTIVO ESCUELA DE NATACIÓN PIRAÑITAS'
      break;
    case 'karina-vivas':
      nombreArreglado = 'ESCUELA DE NATACIÓN KARINA VIVAS'
      break;
    case 'tiburones-blancos':
      nombreArreglado = 'CLUB DEPORTIVO ESCUELA DE NATACIÓN TIBURONES BLANCOS'
      break;
    case 'leones-acuaticos':
      nombreArreglado = 'CLUB DEPORTIVO ESCUELA DE NATACIÓN LEONES ACUATICOS'
      break;
    case 'makos-palmira':
      nombreArreglado = 'CLUB DEPORTIVO ESCUELA DE NATACIÓN MAKOS PALMIRA'
      break;
    case 'dragones-marinos':
      nombreArreglado = 'CLUB DEPORTIVO ESCUELA DE NATACIÓN DRAGONES MARINOS'
      break;
    case 'gold-swimmers':
      nombreArreglado = 'CLUB DEPORTIVO ESCUELA DE NATACIÓN GOLD SWIMMERS'
      break;
    default:
      break;
  }
  return nombreArreglado;
}

export function facturacion(clie, tor, estado, setPdfUrl, descuento, motivo, numeracion){
    const doc = new jsPDF({
      format: 'letter',
    }); 

    let width = 170;
    let height = 170;
    if(estado === 'Pendiente'){
      doc.internal.pageSize.height = 147;
    }else{
      doc.internal.pageSize.height = 125;
      width = 140;
      height = 140;
    }

    // Agregar el logo de la empresa como marca de agua
    const logoImg = new Image();
    logoImg.src = require('../img/marca-de-agua.png');
    doc.addImage(logoImg, 'png', 20, 0, width, height);

    // Configurar la fuente y el tamaño
    doc.setFont('arial', 'italic', 'bold');
    doc.setFontSize(11);
    
    // Título del torneo
    doc.text(`${tor.titulo}\n${arreglarNombres(clie.club)}`, doc.internal.pageSize.width / 2, 10, { align: 'center' });
    doc.setFont('arial');
    doc.setFontSize(20);
    doc.text(`FACTURA PROWEBSPORTS`, doc.internal.pageSize.width / 2, 21, { align: 'center' });
    doc.line(20, 23, 190, 23);

    //estado de la factura
    doc.setFont('arial', '', 'bold');
    doc.setFontSize(15);
    doc.text(`Estado: ${estado}`, 20, 22, { align: 'left' });

    //Numeracion factura
    doc.setFont('arial', '', 'bold');
    doc.setFontSize(15);
    doc.text(`${numeracion}`, 190, 22, { align: 'right' });

    //NIT prowebsports
    doc.setFont('arial', '', 'bold');
    doc.setFontSize(11);
    doc.text(`NIT: 901715567-1`, doc.internal.pageSize.width / 2, 27, { align: 'center' });

    //factura Cliente
    doc.setFont('arial', '', 'bold');
    doc.setFontSize(11);
    doc.text(`Factura a:`, 20, 40, { align: 'left' });
    doc.setFont('arial', '', '');
    doc.text(`${clie.nombre}`, 38, 40, { align: 'left' });

    doc.setFont('arial', '', 'bold');
    doc.setFontSize(11);
    doc.text(`E-mail:`, 20, 44, { align: 'left' });
    doc.setFont('arial', '', '');
    doc.text(`${clie.correo}`, 33, 44, { align: 'left' });

    doc.setFont('arial', '', 'bold');
    doc.setFontSize(11);
    doc.text(`Celular:`, 20, 48, { align: 'left' });
    doc.setFont('arial', '', '');
    doc.text(`${clie.cel}`, 34, 48, { align: 'left' });

    doc.setFont('arial', '', 'bold');
    doc.setFontSize(11);
    doc.text(`Fecha Torneo:`, 20, 52, { align: 'left' });
    doc.setFont('arial', '', '');
    doc.text(`${tor.fecha.replace(/-/g, "/")}`, 46, 52, { align: 'left' });
    doc.line(20, 54, 190, 54);
    doc.line(20, 270, 190, 270);

    //PROWEBSPORTS
    doc.setFont('arial', '', 'bold');
    doc.setFontSize(11);
    doc.text(`Empresa:`, 148, 40, { align: 'right' });
    doc.setFont('arial', '', '');
    doc.text(`PROWEBSPORTS`, 180, 40, { align: 'right' });

    doc.setFont('arial', '', 'bold');
    doc.setFontSize(11);
    doc.text(`E-mail:`, 144.5, 44, { align: 'right' });
    doc.setFont('arial', '', '');
    doc.text(`prowebsports@gmail.com`, 188, 44, { align: 'right' });

    doc.setFont('arial', '', 'bold');
    doc.setFontSize(11);
    doc.text(`Celular:`, 146, 48, { align: 'right' });
    doc.setFont('arial', '', '');
    doc.text(`3245884041`, 166, 48, { align: 'right' });
    
    const hoy = new Date();
    const fechaString = hoy.toLocaleDateString();
    doc.setFont('arial', '', 'bold');
    doc.setFontSize(11);
    doc.text(`Fecha Factura:`, 157, 52, { align: 'right' });
    doc.setFont('arial', '', '');
    doc.text(`${fechaString}`, 176, 52, { align: 'right' });

    // Definir datos de la tabla
    const tabla = [
      ['Descripción', 'Cantidad', 'Unidad', 'Precio', 'Subtotal'],
      [`Deportistas inscritos\nen el torneo.`, `${tor.deportistas}`, 'unidad', `$${(2500).toLocaleString()}`, `$${(parseInt(tor.deportistas) * 2500).toLocaleString()}`],
    ];

    // Definir datos de la tabla descuento
    const descuentoTotal = Math.floor(((parseInt(tor.deportistas) * 2500) * (1 - (parseFloat(descuento)/100))));
    const tablaDescuento = [
      ['Descuento', 'Motivo', '', '', 'Total'],
      [`${descuento}%`, `${motivo}`, '', ``, `$${descuentoTotal.toLocaleString()}`],
    ];

    // Configurar la posición de la tabla descuento
    const startXD = 18;
    const startYD = 82;
    const cellWidthD = 35;
    const cellHeightD = 10;

    // Configurar la posición de la tabla
    const startX = 18;
    const startY = 60;
    const cellWidth = 35;
    const cellHeight = 10;

    doc.setFont('arial', '', '');
    doc.setFontSize(11);
    // Dibujar los bordes de la tabla y las celdas
    for (let i = 0; i < tabla.length; i++) {
      for (let j = 0; j < 5; j++) {
          if (i === 0) {
              doc.setDrawColor(0); // Líneas de encabezado más gruesas
              doc.setFillColor(200, 200, 200); // Fondo de encabezado
              doc.rect(startX + j * cellWidth, startY + i * cellHeight, cellWidth, cellHeight, 'DF');
          } else {
              doc.setDrawColor(0); // Líneas de celdas
              doc.rect(startX + j * cellWidth, startY + i * cellHeight, cellWidth, cellHeight);
          }
      }
    }

    for (let i = 0; i < tablaDescuento.length; i++) {
      for (let j = 0; j < 5; j++) {
          if (i === 0) {
              doc.setDrawColor(0); // Líneas de encabezado más gruesas
              doc.setFillColor(200, 200, 200); // Fondo de encabezado
              doc.rect(startXD + j * cellWidthD, startYD + i * cellHeightD, cellWidthD, cellHeightD, 'DF');
          } else {
              doc.setDrawColor(0); // Líneas de celdas
              doc.rect(startXD + j * cellWidthD, startYD + i * cellHeightD, cellWidthD, cellHeightD);
          }
      }
    }

    // Dibujar el contenido de la tabla
    doc.setFont('arial', '', '');
    for (let i = 0; i < tabla.length; i++) {
        for (let j = 0; j < 5; j++) {
            doc.text(tabla[i][j], startX + j * cellWidth + 2, startY + i * cellHeight + cellHeight / 2);
        }
    }

    // Dibujar el contenido de la tabla descuento
    doc.setFont('arial', '', '');
    for (let i = 0; i < tablaDescuento.length; i++) {
        for (let j = 0; j < 5; j++) {
            doc.text(tablaDescuento[i][j], startXD + j * cellWidthD + 2, startYD + i * cellHeightD + cellHeightD / 2);
        }
    }

    doc.setFont('arial', '', '');
    doc.setFontSize(15);
    doc.text(`Total a pagar:`, 20, 111, { align: 'left' });

    doc.setFont('arial', '', '');
    doc.setFontSize(15);
    doc.text(`COP $${descuentoTotal.toLocaleString()}`, 190, 111, { align: 'right' });
    doc.line(20, 106, 190, 106);
    doc.line(20, 112, 190, 112);

    if(estado === 'Pendiente'){
      //Formas de pago
      doc.setFont('arial', 'italic', 'bold');
      doc.setFontSize(20);
      doc.text(`FORMA DE PAGO`, doc.internal.pageSize.width / 2, 120, { align: 'center' });
      doc.line(20, 121, 190, 121);
      doc.line(20, 140, 190, 140);

      //cuenta nequi y daviplata
      doc.setFont('arial', '', '');
      doc.setFontSize(12);
      doc.text(`Nequi/Daviplata: +57 321-576-4653`, 20, 125, { align: 'left' });
  
      //cuenta bancolombia
      doc.setFont('arial', '', '');
      doc.setFontSize(12);
      doc.text(`Bancolombia: 0313 739 4026`, 20, 130, { align: 'left' });

      //parte de abajo del pdf
      doc.setFontSize(8);
      doc.setFont('arial', 'italic');
      doc.text(`https://prowebsports.web.app`, 20, 143)
      doc.text(`prowebsports@gmail.com`, 160, 143)
      doc.text(`PROWEBSPORTS`, 84, 143)
      doc.text(`+57 324-588-4041`, 106, 143)
      doc.setProperties({
        title: "Factura PROWEBSPORTS"
      });
    }else{
      doc.line(20, 117, 190, 117);

      //parte de abajo del pdf
      doc.setFontSize(8);
      doc.setFont('arial', 'italic');
      doc.text(`https://prowebsports.web.app`, 20, 120)
      doc.text(`prowebsports@gmail.com`, 160, 120)
      doc.text(`PROWEBSPORTS`, 84, 120)
      doc.text(`+57 324-588-4041`, 106, 120)
      doc.setProperties({
        title: "Factura PROWEBSPORTS"
      });
    }

    // Mostrar el PDF en una nueva ventana del navegador
    const generatedPdfBlob = doc.output('blob');
    const pdfUrl = URL.createObjectURL(generatedPdfBlob);
    setPdfUrl(pdfUrl);
  }

export function facturacionFactura(clie, setPdfUrl, numeracion){
    const doc = new jsPDF({
      format: 'letter',
    }); 

    let width = 170;
    let height = 170;
    doc.internal.pageSize.height = 105;
    width = 120;
    height = 100;

    // Agregar el logo de la empresa como marca de agua
    const logoImg = new Image();
    logoImg.src = require('../img/marca-de-agua.png');
    doc.addImage(logoImg, 'png', 40, 0, width, height);

    // Configurar la fuente y el tamaño
    doc.setFont('arial', 'italic', 'bold');
    doc.setFontSize(11);

    // Título del torneo
    doc.text(`${arreglarNombres(clie.club)}\nPROWEBSPORTS`, doc.internal.pageSize.width / 2, 10, { align: 'center' });
    doc.setFont('arial');
    doc.setFontSize(20);
    doc.text(`FACTURA PROWEBSPORTS`, doc.internal.pageSize.width / 2, 21, { align: 'center' });
    doc.line(20, 23, 190, 23);

    //Numeracion factura
    doc.setFont('arial', '', 'bold');
    doc.setFontSize(15);
    doc.text(`${numeracion}`, 190, 22, { align: 'right' });

    //NIT prowebsports
    doc.setFont('arial', '', 'bold');
    doc.setFontSize(11);
    doc.text(`NIT: 901715567-1`, doc.internal.pageSize.width / 2, 27, { align: 'center' });

    //factura Cliente
    doc.setFont('arial', '', 'bold');
    doc.setFontSize(11);
    doc.text(`Factura a:`, 20, 40, { align: 'left' });
    doc.setFont('arial', '', '');
    doc.text(`${clie.nombre}`, 38, 40, { align: 'left' });

    doc.setFont('arial', '', 'bold');
    doc.setFontSize(11);
    doc.text(`E-mail:`, 20, 44, { align: 'left' });
    doc.setFont('arial', '', '');
    doc.text(`${clie.correo}`, 33, 44, { align: 'left' });

    doc.setFont('arial', '', 'bold');
    doc.setFontSize(11);
    doc.text(`Celular:`, 20, 48, { align: 'left' });
    doc.setFont('arial', '', '');
    doc.text(`${clie.cel}`, 34, 48, { align: 'left' });
    doc.line(20, 54, 190, 54);
    doc.line(20, 270, 190, 270);

    //PROWEBSPORTS
    doc.setFont('arial', '', 'bold');
    doc.setFontSize(11);
    doc.text(`Empresa:`, 148, 40, { align: 'right' });
    doc.setFont('arial', '', '');
    doc.text(`PROWEBSPORTS`, 180, 40, { align: 'right' });

    doc.setFont('arial', '', 'bold');
    doc.setFontSize(11);
    doc.text(`E-mail:`, 144.5, 44, { align: 'right' });
    doc.setFont('arial', '', '');
    doc.text(`prowebsports@gmail.com`, 188, 44, { align: 'right' });

    doc.setFont('arial', '', 'bold');
    doc.setFontSize(11);
    doc.text(`Celular:`, 146, 48, { align: 'right' });
    doc.setFont('arial', '', '');
    doc.text(`3245884041`, 166, 48, { align: 'right' });
    
    const hoy = new Date();
    const fechaString = hoy.toLocaleDateString();
    doc.setFont('arial', '', 'bold');
    doc.setFontSize(11);
    doc.text(`Fecha Factura:`, 157, 52, { align: 'right' });
    doc.setFont('arial', '', '');
    doc.text(`${fechaString}`, 176, 52, { align: 'right' });

    // Definir datos de la tabla
    const tabla = [
      ['Descripción', 'Cantidad Meses', '$ Precio Mes', 'Total'],
      [`Servicio de facturación\nPlataforma Web`, `${parseInt(clie.Fpago)}`, `$25.000`, `$${(25000 * parseInt(clie.Fpago)).toLocaleString()}`],
    ];

    // Configurar la posición de la tabla
    const startX = 18;
    const startY = 60;
    const cellWidth = 43;
    const cellHeight = 10;

    doc.setFont('arial', '', '');
    doc.setFontSize(11);
    // Dibujar los bordes de la tabla y las celdas
    for (let i = 0; i < tabla.length; i++) {
      for (let j = 0; j < 4; j++) {
          if (i === 0) {
              doc.setDrawColor(0); // Líneas de encabezado más gruesas
              doc.setFillColor(200, 200, 200); // Fondo de encabezado
              doc.rect(startX + j * cellWidth, startY + i * cellHeight, cellWidth, cellHeight, 'DF');
          } else {
              doc.setDrawColor(0); // Líneas de celdas
              doc.rect(startX + j * cellWidth, startY + i * cellHeight, cellWidth, cellHeight);
          }
      }
    }

    // Dibujar el contenido de la tabla
    doc.setFont('arial', '', '');
    for (let i = 0; i < tabla.length; i++) {
        for (let j = 0; j < 4; j++) {
            doc.text(tabla[i][j], startX + j * cellWidth + 2, startY + i * cellHeight + cellHeight / 2);
        }
    }

    doc.setFont('arial', '', '');
    doc.setFontSize(15);
    doc.text(`Monto pagado:`, 20, 90, { align: 'left' });

    doc.setFont('arial', '', '');
    doc.setFontSize(15);
    doc.text(`COP $${(25000 * parseInt(clie.Fpago)).toLocaleString()}`, 190, 90, { align: 'right' });
    doc.line(20, 85, 190, 85);
    doc.line(20, 92, 190, 92);

    doc.line(20, 96, 190, 96);

    //parte de abajo del pdf
    doc.setFontSize(8);
    doc.setFont('arial', 'italic');
    doc.text(`https://prowebsports.web.app`, 20, 99)
    doc.text(`prowebsports@gmail.com`, 160, 99)
    doc.text(`PROWEBSPORTS`, 84, 99)
    doc.text(`+57 324-588-4041`, 106, 99)
    doc.setProperties({
      title: "Factura PROWEBSPORTS"
    });

    // Mostrar el PDF en una nueva ventana del navegador
    const generatedPdfBlob = doc.output('blob');
    const pdfUrl = URL.createObjectURL(generatedPdfBlob);
    setPdfUrl(pdfUrl);
  }