import React, { useState, useEffect } from 'react';
import { motion } from "framer-motion";
import Conctatenos from './Conctatenos';
import { registrarReaccion, traerClientes, traerReacciones } from '../controllers/configuracionFirebase';
import { fetchData, time, timeLocal } from '../controllers/tiempoLimiteInscripcion';
import { Dropdown } from 'primereact/dropdown';
import { Tooltip as Mensaje } from "react-tooltip";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { getCookieReaccion, setReaccionCookie } from "../controllers/cookies";

const Torneos = ({ pdf, setPdf }) => {

  const [ cliente, setCliente ] = useState([]);
  const [ clientesClub, setClientesClub ] = useState([]);
  const [ selectedClub, setSelectedClub ] = useState(null);
  const [ dialogCotizacion, setDialogCotizacion ] = useState(false);
  const [ dialogChampions, setDialogChampions ] = useState(false);
  const [ reacciones, setReacciones ] = useState();

  function traerImagenTorneo(club){
    const objClub = {
      url: require("../img/img_logo_swimmingT.png"),
      colorTitulo: 'rgb(0,0,0)',
      colorFecha: 'rgb(0,0,0)'
    }
    switch (club) {
      case 'piranitas':
        objClub.url = require("../img/img-fondo-piranitas.webp")
        objClub.colorTitulo = 'linear-gradient(225deg, #CBC2AC, #2E3939)'
        objClub.colorFecha = '#2E3939'
        break;
      case 'polares':
        objClub.url = require("../img/img-fondo-polares.webp")
        objClub.colorTitulo = 'linear-gradient(225deg, #9CBDC3, #0F5D7D)'
        objClub.colorFecha = '#0F5D7D'
        break;
      case 'orcas-valle': 
        objClub.url = require("../img/img-fondo-orcasvalle.webp")
        objClub.colorTitulo = 'linear-gradient(225deg, #4991BF, #183D58)'
        objClub.colorFecha = '#183D58'
        break;
      case 'linavalle': 
        objClub.url = require("../img/img-fondo-linavalle.webp")
        objClub.colorTitulo = 'linear-gradient(225deg, #D0161D, #050D12)'
        objClub.colorFecha = '#050D12'
        break;
      case 'karina-vivas': 
        objClub.url = require("../img/img-fondo-karinavivas.webp")
        objClub.colorTitulo = 'linear-gradient(225deg, #7FADC0, #1A5881)'
        objClub.colorFecha = '#1A5881'
        break;
      case 'fundacion-arbe': 
        objClub.url = require("../img/img-fondo-arbe.webp")
        objClub.colorTitulo = 'linear-gradient(225deg, #C3D6EA, #093A8F)'
        objClub.colorFecha = '#093A8F'
        break;
      case 'fecna': 
        objClub.url = require("../img/img-fondo-fecna.webp")
        break;
      case 'atlas-narino': 
        objClub.url = require("../img/img-fondo-atlas.webp")
        objClub.colorTitulo = 'linear-gradient(225deg, #749DB6, #070F1D)'
        objClub.colorFecha = '#070F1D'
        break;
      case 'leones-acuaticos': 
        objClub.url = require("../img/img-fondo-leonesacuaticos.webp")
        objClub.colorTitulo = 'linear-gradient(225deg, #CBC9C8, #616F7A)'
        objClub.colorFecha = '#616F7A'
        break;
      case 'tiburones-blancos': 
        objClub.url = require("../img/img-fondo-tiburonesblancos.webp")
        objClub.colorTitulo = 'linear-gradient(225deg, #589BA5, #1F3A4B)'
        objClub.colorFecha = '#1F3A4B'
        break;
      case 'makos-palmira': 
        objClub.url = require("../img/img-fondo-makospalmira.webp")
        objClub.colorTitulo = 'linear-gradient(225deg, #33E1E6, #014259)'
        objClub.colorFecha = '#014259'
        break;
      case 'comfacauca': 
        objClub.url = require("../img/img-fondo-comfacauca.webp")
        objClub.colorTitulo = 'linear-gradient(225deg, #7B9C98, #2B504F)'
        objClub.colorFecha = '#2B504F'
        break;
      case 'dragones-marinos': 
        objClub.url = require("../img/img-fondo-dragonesmarinos.webp")
        objClub.colorTitulo = 'linear-gradient(225deg, #109DB0, #0B2F45)'
        objClub.colorFecha = '#0B2F45'
        break;
      case 'gold-swimmers': 
        objClub.url = require("../img/img-fondo-goldswimmers.webp")
        objClub.colorTitulo = 'linear-gradient(225deg, #CEB26F, #56472D)'
        objClub.colorFecha = '#56472D'
        break;
      default:
        break;
    }
    return objClub;
  }

  function pdfOWeb(vencimiento, resultados, website, pdf, club){
    if(vencimiento){
      resultados = window.open('', '_blank');
      resultados.location.href = require(`../resultados/${pdf.toUpperCase() + ' ' + club}.pdf`);
    }else{
      window.open(website, '_blank');
    }
  }

  function enviarALaPlataforma(club, vencimiento, pdf){
    let resultados;
    switch (club) {
      case 'piranitas':
        pdfOWeb(vencimiento, resultados, 'https://pws-piranitas.web.app', pdf, 'PIRAÑITAS');
        break;
      case 'polares':
        pdfOWeb(vencimiento, resultados, 'https://pws-polares.web.app', pdf, 'POLARES');
        break;
      case 'orcas-valle':
        pdfOWeb(vencimiento, resultados, 'https://www.orcas-valle.com', pdf, 'ORCAS VALLE');
        break;
      case 'linavalle':
        pdfOWeb(vencimiento, resultados, 'https://pws-liga-valle.web.app', pdf, 'LINAVALLE');
        break;
      case 'karina-vivas':
        pdfOWeb(vencimiento, resultados, 'https://pws-karina-vivas.web.app', pdf, 'KARINA VIVAS');
        break;
      case 'fundacion-arbe':
        pdfOWeb(vencimiento, resultados, 'https://pws-arbe.web.app', pdf, 'FUNDACION ARBE');
        break;
      case 'fecna':
        pdfOWeb(vencimiento, resultados, 'https://pws-fecna.web.app', pdf, 'FECNA');
        break;
      case 'atlas-narino':
        pdfOWeb(vencimiento, resultados, 'https://pws-atlas.web.app', pdf, 'ATLAS NARIÑO');
        break;
      case 'leones-acuaticos':
        pdfOWeb(vencimiento, resultados, 'https://pws-leones-acuaticos.web.app', pdf, 'LEONES ACUATICOS');
        break;
      case 'tiburones-blancos':
        pdfOWeb(vencimiento, resultados, 'https://pws-tiburones-blancos.web.app', pdf, 'TIBURONES BLANCOS');
        break;
      case 'makos-palmira':
        pdfOWeb(vencimiento, resultados, 'https://pws-makos.web.app', pdf, 'MAKOS PALMIRA');
        break;
      case 'comfacauca':
        pdfOWeb(vencimiento, resultados, 'https://pws-comfacauca.web.app', pdf, 'COMFACAUCA');
        break;
      case 'dragones-marinos':
        pdfOWeb(vencimiento, resultados, 'https://pws-dragones-marinos.web.app', pdf, 'DRAGONES MARINOS');
        break;
      case 'gold-swimmers':
        pdfOWeb(vencimiento, resultados, 'https://pws-gold-swimmers.web.app', pdf, 'GOLD SWIMMERS');
        break;
      default:
        break;
    }
  }

  function arreglarFecha(fecha){
    const auxFecha = new Date(fecha);
    auxFecha.setDate(auxFecha.getDate() + 1);
    const fechaArreglada = auxFecha.toLocaleDateString('es-ES',{ 
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long'})
    return fechaArreglada;
  }

  function onClicReaccion(reaccion){
    if(reacciones){
      if(getCookieReaccion('reaxx') !== reaccion){
        if(getCookieReaccion('reaxx') !== ''){
          reacciones[getCookieReaccion('reaxx')] = `${parseInt(reacciones[getCookieReaccion('reaxx')]) - 1}`; 
          reacciones[reaccion] = `${parseInt(reacciones[reaccion]) + 1}`;
        }else{
          reacciones[reaccion] = `${parseInt(reacciones[reaccion]) + 1}`; 
        }
        registrarReaccion(reacciones);
      }
      setReaccionCookie('reaxx', reaccion);
    }
  }

  function formatNumeroReacciones(number) {
    if (number >= 1e6) {
      // Si es un millón o más, formatea en millones (M)
      return (number / 1e6).toFixed(1).replace(/\.0$/, '') + 'M';
    } else if (number >= 1e3) {
      // Si es mil o más, formatea en miles (k)
      return (number / 1e3).toFixed(1).replace(/\.0$/, '') + 'k';
    } else {
      // Si es menos de mil, devuelve el número original
      return number.toString();
    }
  }

  useEffect(() =>{
    traerReacciones(setReacciones);
  },[])

  useEffect(() =>{
    traerClientes(setCliente);
  },[])

  useEffect(()=>{
    fetchData().then((fech)=>{
      cliente.forEach((clie, indexClie)=>{
        time(clie, indexClie, fech);
        timeLocal(clie.fechaInscripciones, indexClie);
      })
    })
  },[cliente])

  useEffect(() =>{
    function nombresClubs(nombre){
      const clubMinus = nombre.toUpperCase();
      let nombreclub = '';
      if (clubMinus.indexOf('-') !== -1) {
        nombreclub = clubMinus.replace(/-/g, " ");
      } else {
        nombreclub = clubMinus;
      }
  
      return nombreclub;
    }

    function acomodarCliente(){
      const auxClientesClub = [];
      cliente.forEach((clie)=>{
        const objCliente = {
          name: nombresClubs(clie.club),
          code: clie.club
        }
        auxClientesClub.push(objCliente);
      })
      setClientesClub(auxClientesClub);
    }

    acomodarCliente();
  },[cliente])

  return (
    <>
    {/*
      <motion.div className='bg-light p-2'
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{duration: 1}}
      >
        cliente.length !== 0 ?(
          <div className="d-flex justify-content-between align-items-center">
            <Dropdown value={selectedClub} onChange={(e) => setSelectedClub(e.value)} options={clientesClub} optionLabel="name" 
            showClear placeholder="TODOS" style={{width: '14rem'}} />
            <Button icon='pi pi-dollar' className='shadow' severity='success' tooltip='Cotizar' tooltipOptions={{position: 'bottom', mouseTrack: true, mouseTrackTop: 15}} style={{borderRadius: '50%'}}
            onClick={() =>setDialogCotizacion(true)}></Button>
          </div>
        ):(
          <></>
        )
      </motion.div>
      */}
      <motion.div className="text-center"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{duration: 1}}
      >
        <Accordion activeIndex={0}>
          <AccordionTab header="¿Qué opinas de nuestra plataforma?">
            <div className="d-flex justify-content-around mt-2 m-auto" style={{width: '200px'}}>
              <div className="position-relative">
                <img className='img-reaccion' src={require('../img/gif-meencanta.gif')} alt="emoji" onClick={() => onClicReaccion('meencanta')} style={{cursor:'pointer', width: '50px'}}/>
                <span className={`position-absolute start-100 translate-middle badge rounded-pill ${getCookieReaccion('reaxx') === 'meencanta' ? 'bg-danger' : 'bg-success'}`} style={{top: '6px'}}>
                  {reacciones ? formatNumeroReacciones(parseInt(reacciones.meencanta)) : '0'}
                </span>
              </div>
              <div className="position-relative">
                <img className='img-reaccion' src={require('../img/gif-like.gif')} alt="emoji" onClick={() => onClicReaccion('like')} style={{cursor:'pointer', width: '50px'}}/>
                <span className={`position-absolute start-100 translate-middle badge rounded-pill ${getCookieReaccion('reaxx') === 'like' ? 'bg-danger' : 'bg-success'}`} style={{top: '6px'}}>
                  {reacciones ? formatNumeroReacciones( parseInt(reacciones.like)) : '0'}
                </span>
              </div>
              <div className="position-relative" style={{top: '12px'}}>
                <img className='img-reaccion' src={require('../img/gif-dislike.gif')} alt="emoji" onClick={() => onClicReaccion('dislike')} style={{cursor:'pointer', width: '50px'}}/>
                <span className={`position-absolute start-100 translate-middle badge rounded-pill ${getCookieReaccion('reaxx') === 'dislike' ? 'bg-danger' : 'bg-success'}`} style={{top: '-4px'}}>
                  {reacciones ? formatNumeroReacciones(parseInt(reacciones.dislike)) : '0'}
                </span>
              </div>
            </div>
          </AccordionTab>
        </Accordion>
      </motion.div>
      <motion.div className="w-100 row row-cols-1 row-cols-sm-2 row-cols-md-4 justify-content-center align-items-start position-relative" id="torneos" style={{zIndex: 100}}
      initial={{opacity: 0}}
      animate={{opacity: 1}}
      transition={{duration: 1}}
      >
        <div className="position-fixed" style={{bottom: '10px', right: '0', zIndex: 10000, width: '90px'}}>
          <Button icon='pi pi-dollar' className='shadow' size="large" severity='success' tooltip='Cotizar' tooltipOptions={{position: 'bottom', mouseTrack: true, mouseTrackTop: 15}} style={{borderRadius: '50%'}}
          onClick={() =>setDialogCotizacion(true)}></Button>
        </div>
        <motion.div
          className={`position-relative card m-2 pt-2 col shadow card-torneo`}
          style={{ width: "22rem" }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: `0.5` }}
        >
          <span className='linea linea-left'></span>
          <span className='linea linea-right'></span>
          <span className='linea linea-top'></span>
          <span className='linea linea-bottom'></span>
          <div className="d-flex justify-content-between">
            <div
              className="d-flex align-items-center position-relative"
            >
              <p
                className='mb-0'
                style={{ fontSize: "15px", fontFamily: "Calistoga" }}
              >
                Publicidad
              </p>
            </div>
          </div>
          <img
            src={require("../img/mascotapws.webp")}
            className="card-img-top m-auto position-relative"
            alt="imagen del torneo"
            style={{ width: "200px", filter: "drop-shadow(-1px 1px 2px black)"}}
          />
          <div className="card-body p-0 text-center position-relative">
            <h4 className="card-title position-relative mt-2 mb-0" style={{ fontFamily: "Alkatra" }}>
              INICIATIVA
            </h4>
            <h5 className="card-title position-relative" style={{ fontFamily: "Alkatra" }}>
              PROWEBSPORTS CHAMPIONS
            </h5>
            <p
              className="card-text"
              style={{
                fontSize: "12px",
                fontFamily: "Poppins",
                textAlign: "justify",
              }}
            >
              ¡Invita a tus atletas a ser parte de <b>PROWEBSPORTS CHAMPIONS</b>! Este nuevo proyecto premia el talento y esfuerzo en natación. Al afiliarse por COP 50,000 al año, los deportistas no solo competirán en diversas pruebas, sino que también podrán ganar atractivos premios en efectivo.
            </p>
            <Button icon='pi pi-comments' type="button" label='Llenar Formulario' className="btn btn-primary mb-2" onClick={() => setDialogChampions(true)}></Button>
          </div>
        </motion.div>
        {cliente.length !== 0 ?(
          cliente.filter((clie)=>clie.club.includes(selectedClub?.code ? selectedClub.code : '')).map((torn, indexTorn)=>{
            return <motion.div
            className={`position-relative card m-2 p-0 col ${torn.vencimiento ? 'no-activo' : 'activo'}`}
            style={{ width: "22rem", cursor: 'pointer'}}
            onClick={() => enviarALaPlataforma(torn.club, torn.vencimiento, torn.titulo)}
            key={indexTorn}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: `0.${indexTorn}5` }}
          >
            <span className='linea linea-left'></span>
            <span className='linea linea-right'></span>
            <span className='linea linea-top'></span>
            <span className='linea linea-bottom'></span>
            <div className='position-relative'>
              <img className='w-100' style={{height: '300px'}} src={traerImagenTorneo(torn.club).url} alt="imagen logo club" />
              <div className="w-100 position-absolute top-0 text-dark bg-light shadow">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center" id={`cantNadadores${indexTorn}`}>
                    <img
                      className="ms-2"
                      style={{ width: "18px", marginBottom: '1px' }}
                      src={require("../img/icon-usuario-curso.webp")}
                      alt="icono del usuario"
                    />
                    <motion.p
                      className='m-0'
                      style={{ fontSize: "18px", fontFamily: "Poppins" }}
                    >
                      {torn.deportistas}
                    </motion.p>
                  </div>
                  <div className='text-end me-2' id={`contenedor-diaLocal${indexTorn}`} style={{fontFamily: 'Poppins', fontSize: '15px'}}>
                    <span>Cierre en </span>
                    <span id={`diaLocal${indexTorn}`}>00</span><span>D : </span>
                    <span id={`horaLocal${indexTorn}`}>00</span><span>H : </span>
                    <span id={`minutoLocal${indexTorn}`}>00</span><span>M : </span>
                    <span id={`segundoLocal${indexTorn}`}>00</span><span>S</span> 
                  </div>
                </div>
              </div>
              <div className='d-flex position-absolute bg-light bottom-0 ps-1 pe-1 shadow' style={{fontFamily: 'Poppins', fontSize: '13px', borderRadius: '0 10px 0 0'}}>
                <p className='m-0' id={`inscripcionesCerradas${indexTorn}`}></p>
                <div id={`contenedor-fecha${indexTorn}`}>
                  <span>Cierre en </span>
                  <span id={`dia${indexTorn}`}>00</span>
                  <span id={`dia-hora${indexTorn}`}></span>
                </div>
              </div>
              <Mensaje
                anchorId={`cantNadadores${indexTorn}`}
                place="bottom"
                content={`Inscritos`}
                className="bg-primary shadow"
                style={{ zIndex: 1}}
              />
            </div>
            <div className="position-relative card-body text-center text-light" style={{background: `${traerImagenTorneo(torn.club).colorTitulo}`}}>
              <div className="position-absolute bottom-0" id={`marquee${indexTorn}`} style={{fontFamily: 'Poppins', fontSize: '14px'}}>
                <span id={`marqueeSpan${indexTorn}`}></span>
              </div>
              <div style={{fontFamily: 'Poppins'}}>
                {torn.titulo.toUpperCase()}
              </div>
            </div>
            <div className="card-footer ps-2 p-0 text-start text-light" style={{background: `${traerImagenTorneo(torn.club).colorFecha}`}}>
              Jornada 1 el {arreglarFecha(torn.fecha)}
            </div>
          </motion.div>
          })
        ):(
          <> </>
        )}
      </motion.div>
      <Dialog visible={dialogCotizacion} maximizable style={{ width: '50em' }} breakpoints={{ '641px': '100%' }} header='Cotización' modal className="p-fluid" onHide={() => setDialogCotizacion(false)}>
        <div className="field">
          <div className='mb-2 pdf-min pdf-max' style={{width: '100%'}}>
            <embed src={require('../archivos/cotizacion.pdf')} width='100%' height='100%' type='application/pdf'/>
          </div>
        </div>
      </Dialog>
      <Conctatenos/>
      <Dialog visible={dialogChampions} style={{ width: '50em', height: '2397px' }} breakpoints={{ '700px': '100%' }} header="Encuesta PROWEBSPORTS CHAMPIONS" modal className="p-fluid" onHide={() => setDialogChampions(false)}>
        <div className="field">
          <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSe5SsAEAHqX1_MeGT1cPRyXGzNJGO7fDIW4e9LfWeCzjQgw-Q/viewform?embedded=true" width="100%" height="2550" frameborder="0" marginheight="0" marginwidth="0">Cargando…</iframe>
        </div>
      </Dialog>
    </>
  );
}

export default Torneos;