import './App.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { motion } from "framer-motion";
import { Toaster } from 'react-hot-toast';
import Loading from './components/Loading';
import WebFont from 'webfontloader';
import Inicio from './components/Inicio';
import Caracteristicas from './components/Caracteristicas';
import SobreNosotros from './components/SobreNosotros';
import Pdfs from './components/Pdfs';
import Torneos from './components/Torneos';
import Sesion from './components/Sesion';
import { sesionIniciada } from './controllers/configuracionFirebase';
import Ranking from './components/Ranking';

function App() {

  const [ usuario, setUsuario ] = useState();
  const [ isLoading, setIsLoading ] = useState(true);
  const [ seconds, setSeconds ] = useState(3);
  const [ pdf, setPdf ] = useState();

  useEffect(() => {
    sesionIniciada(setUsuario);
    WebFont.load({
      google: {
        families: [
          'Abril Fatface',
          'IBM Plex Mono', 
          'Acme',
          'Yeseva One',
          'Fira Sans Extra Condensed',
          'Poppins',
          'Merriweather Sans',
          'Bebas Neue',
          'Bodoni Moda'
        ]
      }
    });

    const interval = setInterval(() => {
      setSeconds((seconds) => seconds - 1);
    }, 920);
    if (seconds === 0) {
      setIsLoading(false);
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [setIsLoading, seconds, setUsuario])

  return (
    <div className="App">
      {isLoading ?(
        <Loading/>
      ):(
      <Router>
        <motion.nav
          id="navbar-example2"
          className="navbar navbar-dark navbar-expand-lg bg-dark"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          style={{zIndex: 1000}}
        >
          <div className="container-fluid">
            <Link
              className="navbar-brand d-flex align-items-center"
              to="/admin"
            >
              <img
                style={{ maxWidth: "250px" }}
                src={require("./img/img_logo_swimmingTLetras.png")}
                alt="logo prowebsports"
              />
            </Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="w-100 navbar-nav justify-content-end me-auto mb-2 mb-lg-0">
                <li className="nav-item item-principal">
                  <Link
                    className="nav-link link-principal"
                    to="/"
                  >TORNEOS</Link>
                </li>
                <li className="nav-item item-principal">
                  <Link
                    className="nav-link link-principal"
                    to="/ranking"
                  >RANKING</Link>
                </li>
                <li className="nav-item item-principal">
                  <Link
                    className="nav-link link-principal"
                    to="/inicio"
                  >INICIO</Link>
                </li>
                <li className="nav-item item-principal">
                  <Link
                    className="nav-link link-principal"
                    to="/caracteristicas"
                  >CARACTERÍSTICAS</Link>
                </li>
                <li className="nav-item item-principal">
                  <Link
                    className="nav-link link-principal"
                    to="/sobrenosotros"
                  >SOBRE NOSOTROS</Link>
                </li>
              </ul>
            </div>
          </div>
        </motion.nav>
        <div className="bgColombia"></div>
        <div className="bg"></div>
        <div className="bg bg2"></div>
        <div className="bg bg3"></div>
        <Routes>
          <Route
            path="/sobrenosotros"
            element={
              <SobreNosotros />
            }
          />
          <Route
            path="/pdf"
            element={
              <Pdfs filePdf={pdf}/>
            }
          />
          {/*
          <Route
            path="/paquetes"
            element={
              <Precios />
            }
          />
          */}
          <Route
            path="/caracteristicas"
            element={
              <Caracteristicas />
            }
          />
          <Route
            path="/inicio"
            element={
              <Inicio />
            }
          />
          <Route
            path="/ranking"
            element={
              <Ranking />
            }
          />
          <Route
            path="/admin/*"
            element={
              <Sesion usuario={usuario} />
            }
          />
          <Route
            path="/*"
            element={
              <Torneos pdf={pdf} setPdf={setPdf}/>
            }
          />
        </Routes>
      </Router>
      )}
      <Toaster containerStyle={{textAlign: 'start'}}/>
    </div>
  );
}

export default App;
