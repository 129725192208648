import React, { useState } from "react";
import "../styles/Sesion.css";
import Login from "./Login";
import { motion } from "framer-motion";
import Panel from "./Panel";

const Sesion = ({ usuario }) => {

  const [ login, setLogin ] = useState(true);

  return usuario ? (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <Panel usuario={usuario}/>
    </motion.div>
  ) : (
    <>
      <div className="w-100 d-flex justify-content-center align-items-center posicion-sesion" style={{height: '100%'}}>
        <div className="w-100">
          <motion.div
            className="m-auto sesion"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <div className="me-5"></div>
            <Login setLogin={setLogin} loginv={login} />
            <div className={login ? "izquierda sesion-i" : "derecha sesion-i"}>
              <img
                className={login ? "img1 img p-2" : "img2 img"}
                src={
                  login
                    ? require("../img/img_logo_swimmingT.png")
                    : require("../img/img_logo_swimmingT.png")
                }
                alt="logos"
              />
            </div>
          </motion.div>
          <motion.div className="prowebsports"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          >
            <p>© 2023 prowebsports</p>
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default Sesion;
