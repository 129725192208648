import React, {useEffect, useState} from 'react';
import { motion } from "framer-motion";
import Conctatenos from './Conctatenos';
import ReactPlayer from "react-player";
import videoFile from '../videos/loading.mp4';
import { Link } from "react-router-dom";
import { Carousel } from 'primereact/carousel';
import { Tag } from 'primereact/tag';

const Inicio = () => {


  /*
  {image: require('../img/img-fondo-leonesacuaticos.webp'), name: 'LEONES ACUATICOS YUMBO', sitio: 'https://pws-leones-acuaticos.web.app'},
  */

  const products = [
    {image: require('../img/img-fondo-orcasvalle.webp'), name: 'ORCAS VALLE', sitio: 'https://orcas-valle.com'},
    {image: require('../img/img-fondo-atlas.webp'), name: 'ATLAS NARIÑO', sitio: 'https://pws-atlas.web.app'},
    {image: require('../img/img-fondo-atlas-futbol.png'), name: 'ATLAS FUTBOL NARIÑO', sitio: 'https://pws-atlas-futbol.web.app'},
    {image: require('../img/img-fondo-arbe.webp'), name: 'FUNDACIÓN ARBE', sitio: 'https://pws-arbe.web.app'},
    {image: require('../img/img-fondo-piranitas.webp'), name: 'PIRAÑITAS CALDAS', sitio: 'https://pws-piranitas.web.app'},
    {image: require('../img/img-fondo-karinavivas.webp'), name: 'KARINA VIVAS TRUJILLO', sitio: 'https://pws-karina-vivas.web.app'},
    {image: require('../img/img-fondo-polares.webp'), name: 'POLARES BUGALAGRANDE', sitio: 'https://pws-polares.web.app'},
    {image: require('../img/img-fondo-tiburonesblancos.webp'), name: 'TIBURONES BLANCOS', sitio: 'https://pws-tiburones-blancos.web.app'},
    {image: require('../img/img-fondo-makospalmira.webp'), name: 'MAKOS PALMIRA', sitio: 'https://pws-makos.web.app'},
    {image: require('../img/img-fondo-comfacauca.webp'), name: 'COMFACAUCA', sitio: 'https://pws-comfacauca.web.app'},
    {image: require('../img/img-fondo-dragonesmarinos.webp'), name: 'DRAGONES MARINOS', sitio: 'https://pws-dragones-marinos.web.app'},
  ]
  const responsiveOptions = [
    {breakpoint: '767px', numVisible: 2,numScroll: 1},
    {breakpoint: '575px', numVisible: 1, numScroll: 1}
  ];

  const productTemplate = (product) => {
    return (
        <div className="border-1 surface-border border-round m-2 text-center py-5 px-3">
            <div className="mb-3">
                <img src={`${product.image}`} alt={product.name} className="w-100 shadow" style={{borderRadius: '10px'}} />
            </div>
            <div>
                <Link to={product.sitio} target='_blank'>
                  <Tag className='shadow' icon='pi pi-link' value={product.name} severity='primary'></Tag>
                </Link>
            </div>
        </div>
    );
  };

  return (
    <>
      <motion.div id="inicio" style={{zIndex: 100}}
      initial={{opacity: 0}}
      animate={{opacity: 1}}
      transition={{duration: 1}}
      >
        <div className='d-flex flex-wrap align-items-center text-center container-inicio mt-3' style={{height: '100%'}}>
          <motion.div
          initial={{x: -200}}
          whileInView={{x: 0}}
          transition={{duration: 1}}
          className='w-50'
          style={{minWidth: '450px'}}
          >
            <video autoPlay loop muted style={{borderRadius: '0 50px 50px 0'}} className='w-100 shadow img-inicio'>
              <source src={videoFile} type="video/mp4" />
              Tu navegador no admite la reproducción de videos.
            </video>
          </motion.div>
          <motion.div className='inicio m-auto'
          initial={{opacity: 0}}
          whileInView={{opacity: 1}}
          transition={{duration: 1.2, delay: 0.3}}
          style={{maxWidth: '540px'}}
          >
            <div>
              <h1 style={{fontFamily: 'Yeseva One', fontSize: '58px'}}>Bienvenidos</h1>
            </div>
            <div style={{fontFamily: 'Fira Sans Extra Condensed', textAlign: 'justify'}}>
                <p>
                  ¡Bienvenido al mundo de las competencias de natación optimizadas! Creamos plataformas personalizadas para clubs de natación, ofreciéndote una solución adaptada a tus necesidades. Crea tus propios torneos, registra clubes, inscribe nadadores, ingresa tiempos, visualiza resultados en tiempo real y mantén un seguimiento de qué club va ganando el trofeo. Te brindamos una plataforma eficiente y accesible. ¡Contáctanos para llevar tus competencias al siguiente nivel!
                </p>
            </div>
          </motion.div>
        </div>
        <motion.div className='d-flex flex-wrap justify-content-around mt-3'
        initial={{opacity: 0}}
        whileInView={{opacity: 1}}
        transition={{duration: 1}}
        >
          <div style={{maxWidth: '600px'}}>
            <h4 className='pt-3' style={{fontFamily: 'Yeseva One'}}>¡Optimiza tus competencias con nosotros!</h4>
            <p className='p-3' style={{fontFamily: 'Fira Sans Extra Condensed', textAlign: 'justify'}}>
              Con nosotros, obtendrás una plataforma exclusiva con un enlace personalizado: pws-nombredetuclub.web.app. Tendrás la flexibilidad de cambiarlo según tus preferencias. Experimentarás una gestión de torneos mucho más organizada, dejando atrás los métodos manuales. Ahora, todas tus tareas podrán realizarse fácilmente a través de tu propia plataforma.
              Algunas funciones se ejecutarán automáticamente, liberándote de la gestión constante. Nosotros nos encargaremos de hacer que tus torneos sean más eficientes, bien organizados y libres de estrés. Así, disfrutarás al máximo del deporte, viviendo momentos destacados en cada torneo.
            </p>
          </div>
          {/*
          <div className='shadow' style={{maxWidth: '700px'}}>
            <ReactPlayer
              url="https://www.youtube.com/watch?v=a-DClyo_QKM&t=4s"
              controls
              loop
              volume={0.5}
            />
          </div>
          */}
        </motion.div>
        <motion.div className='mt-5'
        initial={{opacity: 0}}
        whileInView={{opacity: 1}}
        transition={{duration: 1}}
        >
          <p className='mb-0' style={{
            fontFamily: 'Yeseva One', 
            fontSize: '2.5em',
            }}>Confían en Nosotros</p>
          <p style={{fontFamily: 'Acme', fontSize: '1.5em'}}>¿Que esperas para optimizar tus competencias con nosotros?</p>
        </motion.div>
        <motion.div
        initial={{opacity: 0}}
        whileInView={{opacity: 1}}
        transition={{duration: 1}}
        >
          <Carousel value={products} numVisible={5} numScroll={5} responsiveOptions={responsiveOptions} className="custom-carousel" circular
          autoplayInterval={6000} itemTemplate={productTemplate} />
        </motion.div>
      </motion.div>
      <Conctatenos/>
    </>
  );
}

export default Inicio;